import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <img src="dc4p-logo.png" 
             alt="dc4p logo"
             width={100}
             height={100}></img>
        
        <p>The Swift 'Accordion' - Language Guide</p>
      
        <iframe width="560" height="315" src="https://www.youtube.com/embed/videoseries?si=JKcepM6GM--8Kf-b&amp;list=PLxk7YM06oVwLmB8QoWn8C9yXR1_nFSZbN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

        <script src="https://gumroad.com/js/gumroad.js"></script>
       
        <button class="button">
          <a
            href="https://dc4p.gumroad.com/l/the-swift-accordion-language-guide-index" 
            data-gumroad-overlay-checkout="true">I want this
          </a>
        </button>
      </header>
    </div>
  );
}

export default App;
